import React from "react";
import styled from 'styled-components';
import { moreWork } from "../data/moreWork";
import { isMobile } from "../helpers";
import aaron_pic from '.././assets/aaron_pic.png';


function Archive() {

    return (
        <ArchiveWrapper>
            <PageWrapper>
                <PageHeaderWrapper>
                    <LinkWrapper href={'/'} >
                        <PageHeader>
                            <PageHeaderImage src={aaron_pic} />
                            <PageHeaderTitle>
                                Aaron Edwards
                        </PageHeaderTitle>
                        </PageHeader>
                    </LinkWrapper>
                </PageHeaderWrapper>

                <ListHeader>
                    <ListTitle>
                        Work
                    </ListTitle>
                    {!isMobile() && <ListDescription>
                        Role
                    </ListDescription>}
                    <ListDate>
                        With
                    </ListDate>
                </ListHeader>
                <ListWrapper>
                    {moreWork.map((item) => {
                        return (
                            <LinkWrapper href={item.url} target="_blank" rel="noreferrer">
                                <ProjectItemWrapper>
                                    <WorkTitle>{item.title}</WorkTitle>
                                    {!isMobile() && <WorkDescription>
                                        {item.role}
                                    </WorkDescription>}
                                    <SourceColumn>
                                        {item.source}
                                    </SourceColumn>
                                </ProjectItemWrapper>
                            </LinkWrapper>
                        )
                    })}
                </ListWrapper>
            </PageWrapper>
        </ArchiveWrapper>

    );
}

export default Archive;


/* Page Styles */

const ArchiveWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    padding-bottom: 200px;

    @media only screen and (max-width: 600px) {
        margin-top: 20px;
        padding-bottom: 100px;
    }
`;

const PageWrapper = styled.div`
    width: 100%;
    max-width: 1300px;
    padding: 0 50px;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media only screen and (max-width: 600px) {
        padding: 20px;
    }
`;


/* Page Header Styles */

const PageHeaderWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 100px;


    @media only screen and (max-width: 600px) {
        margin-bottom: 60px;
    }
`;

const PageHeader = styled.div`
    display: flex;
`;

const PageHeaderImage = styled.img`
    height: 40px;
    margin-right: 10px;

    @media only screen and (max-width: 600px) {
        height: 28px;
    }
`;

const PageHeaderTitle = styled.p`
    font-size: 35px;

    @media only screen and (max-width: 600px) {
        font-size: 25px;
    }
`;


/* List Header Styles */

const ListTitle = styled.div`
    flex-grow: 1;
    display: flex;  
`;

const ListDescription = styled.div`
    display: flex;
    margin-right: 50px;
`;

const ListDate = styled.div`
    display: flex;
    min-width: 225px;
    flex-direction: column;
    align-items: flex-end;

    @media only screen and (max-width: 600px) {
        min-width: 125px;
    }
`;

const ListHeader = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    padding-bottom: 8px;
    font-style: italic;
    letter-spacing: 1px;
    font-size: 20px;
    border-bottom: 1px solid #D9D9D9;

    @media only screen and (max-width: 600px) {
        padding-bottom: 8px;
    }
`;


/* List Styles */

const LinkWrapper = styled.a`
    color: inherit;
    text-decoration: none;
`;

const WorkDescription = styled.div`
    font-style: italic;
    display: flex;
    margin-right: 50px;
    margin-left: 50px;
    font-family: forma-djr-deck, sans-serif;
    font-weight: 400;
    letter-spacing: 1px;
`

const ListWrapper = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
`;

const ProjectItemWrapper = styled.div`
    display: flex;
    flex-direction: row;
    padding: 15px 0px;
    border-bottom: 1px solid #D9D9D9;
    margin-top: -1px;
    font-size: 25px;
    cursor: pointer;
    transition: color 0.25s;
    width: 100%;
    
    :hover {
        color: #E97522;
    }

    @media only screen and (max-width: 600px) {
        font-size: 16px;

        :hover {
            color: black;
        }
    }


`;

const SourceColumn = styled.div`
    display: flex;
    font-weight: 400;
    letter-spacing: 1px;
    min-width: 225px;
    text-align: right;
    flex-direction: column;
    align-items: flex-end;
    font-size: 28px;
    color: #E97522;
    font-style: italic;

    @media only screen and (max-width: 600px) {
        min-width: 125px;
        font-size: 18px;
    }
`;

const WorkTitle = styled.div`
    flex-grow: 1;
    display: flex;
    font-size: 25px;
    letter-spacing: 1px;
    font-weight: 400;
    font-family: forma-djr-deck, sans-serif;

    @media only screen and (max-width: 600px) {
        font-size: 16px;
    }
`;