import React, { useRef, useEffect, useState } from "react";
import styled from 'styled-components';
import { isMobile } from "./helpers";
import { carouselData } from "./data/carouselData";
import ProjectCard from "./ProjectCard";

const SCROLL_SPEED = isMobile() ? 22 : 15;
const ENTRANCE_DELAY = isMobile() ? 500 : 500;

function useInterval(callback, delay) {
    const savedCallback = useRef();

    // Remember the latest function.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}

function Projects() {
    const [direction, setDirection] = useState('left');
    const [scrollInterval, setscrollInterval] = useState(1);
    const projectWrapper = useRef();
    const [hoveredItem, setHoveredItem] = useState(null);
    const [initialDelay, setinitialDelay] = useState(true);
    const [isUserTouchingMobile, setIsUserTouchingMobile] = useState(false);
    const [canBeScrolled, setCanBeScrolled] = useState(false);
    const [mobileTouchSpot, setMobileTouchSpot] = useState(null);

    const getNewPosition = (scrollLeft, scrollInterval) => {
        // get new position
        let new_position;
        if (direction === 'left') {
            new_position = Math.ceil(scrollLeft + scrollInterval)
        } else if (direction === 'right') {
            new_position = Math.ceil(scrollLeft - scrollInterval)
        }

        return new_position;
    }

    const handleScroll = () => {
        const projectDiv = projectWrapper.current;
        const oldScrollLeft = projectDiv.scrollLeft;

        // get new position
        const new_position = getNewPosition(projectDiv.scrollLeft, scrollInterval);

        //scroll
        projectDiv.scrollTo(new_position, 0, 'smooth');

        // check if worked until it works
        while (projectDiv.scrollLeft === oldScrollLeft) {
            // update interval
            const newInterval = scrollInterval + 0.1;
            setscrollInterval(newInterval);

            // get new position & scroll
            const new_position = getNewPosition(projectDiv.scrollLeft, newInterval)
            projectDiv.scrollTo(new_position, 0, 'smooth');
        }
    }

    useInterval(() => {
        if (hoveredItem || isUserTouchingMobile) {
            return;
        }

        if (initialDelay) {
            setTimeout(() => {
                setinitialDelay(false);
            }, ENTRANCE_DELAY);
            return;
        }

        const projectDiv = projectWrapper.current;
        const maxScroll = projectDiv.scrollWidth - projectDiv.clientWidth;

        if (direction === 'left' && projectDiv.scrollLeft < maxScroll) {
            handleScroll();
        } else if (direction === 'right' && projectDiv.scrollLeft > 0) {
            handleScroll();
        } else if (projectDiv.scrollLeft >= maxScroll) {
            setDirection('right');
        } else if (projectDiv.scrollLeft === 0) {
            setDirection('left');
        }

    }, SCROLL_SPEED);

    useEffect(() => {
        const projectDiv = projectWrapper.current;

        projectDiv.addEventListener('touchstart', (e) => {
            if (isMobile()) {
                setIsUserTouchingMobile(true);
                setMobileTouchSpot(e.touches[0].clientX);
            }
        });

        projectDiv.addEventListener('touchmove', (e) => {
            if (isMobile()) {
                setIsUserTouchingMobile(true);
                const new_spot = e.changedTouches[0].clientX;

                if (mobileTouchSpot > new_spot) {
                    setDirection('left');
                } else {
                    setDirection('right');
                }

            }
        });

        projectDiv.addEventListener('touchend', function () {
            if (isMobile()) {
                const projectDiv = projectWrapper.current;
                let oldScrollLeft = projectDiv.scrollLeft;

                function testIfScrolling() {
                    if (projectDiv.scrollLeft !== oldScrollLeft) {
                        oldScrollLeft = projectDiv.scrollLeft;
                        window.requestAnimationFrame(testIfScrolling);
                    } else {
                        setTimeout(() => {
                            setIsUserTouchingMobile(false);
                        }, 250);

                    }
                }

                window.requestAnimationFrame(testIfScrolling);
            }

        });

    }, [mobileTouchSpot]);

    const onWheel = (e) => {

        if (e.deltaY > 0 || e.deltaX > 0) {
            setDirection('left');
        } else if (e.deltaY < 0 || e.deltaX < 0) {
            setDirection('right');
        }

        if (!canBeScrolled || isMobile()) {
            return false;
        }

        if (e.deltaY > 0) {
            setDirection('left');
        } else if (e.deltaY < 0) {
            setDirection('right');
        }

        const projectDiv = projectWrapper.current;
        const containerScrollPosition = projectDiv.scrollLeft;
        const maxScroll = projectDiv.scrollWidth - projectDiv.clientWidth;

        if (projectDiv.scrollLeft <= 0) {
            enableVerticalScroll();
        } else if (projectDiv.scrollLeft >= maxScroll) {
            enableVerticalScroll();
        } else {
            disableVerticalScroll();
        }

        projectDiv.scrollTo({
            top: 0,
            left: containerScrollPosition + e.deltaY,
            behaviour: "smooth"
        });
        return false;
    };

    const disableVerticalScroll = (e) => {
        if (!isMobile()) {
            document.body.style.overflow = "hidden";
        }
    }

    const mouseLeaveScroll = () => {
        if (!isMobile()) {
            setCanBeScrolled(false);
            enableVerticalScroll();
        }
    }

    const enableVerticalScroll = (e) => {
        if (!isMobile()) {
            document.body.style.overflow = "auto";
        }
    }

    return (
        <ProjectCarouselWrapper>
            <CarouselWrapper
                ref={projectWrapper}
                onWheel={onWheel}
                onMouseLeave={mouseLeaveScroll}
                onMouseOver={() => setCanBeScrolled(true)}
            >
                <FakeDivFront />
                {carouselData.map((item) => {
                    return (
                        <ProjectCard
                            data={item}
                            hoveredItem={hoveredItem}
                            onHover={(item_id) => setHoveredItem(item_id)}
                            onUnhover={() => setHoveredItem(null)}
                        />)
                })}
                <FakeDivBack />
            </CarouselWrapper>
        </ProjectCarouselWrapper>

    );
}

export default Projects;

const FakeDivFront = styled.div`
    min-width: 40vw;
    min-height: 100%;
`;

const FakeDivBack = styled.div`
    min-width: 50vw;
    min-height: 100%;
`;

const ProjectCarouselWrapper = styled.div`
    max-width: 100%;
    overflow-x: scroll;
    margin-top: 80px;
    z-index: 3;
    margin-bottom: 30px;

    ::-webkit-scrollbar {
        width: 0;  /* Remove scrollbar space */
        height: 0;
        background: transparent;  /* Optional: just make scrollbar invisible */
        -webkit-appearance: none;
        background-color: transparent;
        height: 0;
        width: 0;
        color: #EEEEEE;
    }

    @media only screen and (max-width: 600px) {;
        margin-top: 50px;
        position: relative;
    }
`;

const CarouselWrapper = styled.div`
    display: flex;
    flex-wrap: nowrap;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; 
    overflow-x: scroll;
    height: 100%;
    overflow-y: hidden;
    
    ::-webkit-scrollbar {
        width: 0;  /* Remove scrollbar space */
        height: 0;
        background: transparent;  /* Optional: just make scrollbar invisible */
        -webkit-appearance: none;
        background-color: transparent;
        height: 0;
        width: 0;
        color: #EEEEEE;
    }
`;