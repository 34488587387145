import React from "react";
import styled from 'styled-components'
import { isMobile } from "./helpers";

class ProjectCard extends React.Component {
    openInNewTab = () => {
        const { data } = this.props;
        const newWindow = window.open(data.url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    render() {
        const { hoveredItem, onHover, onUnhover, data } = this.props;
        const isHovered = hoveredItem === data.id;
        const otherItemHovered = hoveredItem && hoveredItem !== data.id;

        return (
            <ProjectCardWrapper
                onMouseEnter={() => onHover(data.id)}
                onMouseLeave={onUnhover}
                onClick={() => this.openInNewTab()}
                style={{
                    opacity: otherItemHovered ? '40%' : '100%',
                    zIndex: isHovered ? 10 : null
                }}
            >
                <ImageWrapper src={data.image} />
                <TextWrapper>
                    <Title>
                        <LinkWrapper href={data.url} target="_blank" rel="noreferrer">
                            {data.title} | <SourceSpan>{data.source}</SourceSpan>
                        </LinkWrapper>
                    </Title>
                    {isMobile() ?
                        <MobileSubtitle>
                            {data.subtitle}
                        </MobileSubtitle>
                        :
                        <SubTitleWrapper>
                            <SubTitle
                                style={{
                                    top: isHovered ? 0 : '100%',
                                    height: isHovered ? '100%' : '0%',
                                }}
                            >
                                {data.subtitle}
                            </SubTitle>
                        </SubTitleWrapper>
                    }
                </TextWrapper>

            </ProjectCardWrapper>
        )
    }
}

export default ProjectCard;

const SourceSpan = styled.span`
    font-style: italic;
`;

const TextWrapper = styled.div`
    padding-top: 10px;
    position: relative;
`;

const LinkWrapper = styled.a`
    text-decoration: none;
    color: inherit;
`;

const Title = styled.p`
    font-family: forma-djr-deck, sans-serif;
    font-weight: 400;
    font-size: 20px;
    letter-spacing: 1px;

    @media only screen and (max-width: 600px) {
        font-size: 16px;
    }
`;

const SubTitleWrapper = styled.div`
    position: relative;
    height: 100%;
`;

const SubTitle = styled.p`
    font-family: forma-djr-deck, sans-serif;
    letter-spacing: 1px;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    padding-top: 5px;
    top: 100%;
    right: 0;
    overflow: hidden;
    width: 100%;
    height: 0%;
    transition: .5s ease;
    color: #4E4E4E;

    @media only screen and (max-width: 600px) {
        font-size: 14px;
    }
`;

const MobileSubtitle = styled.p`
    font-family: forma-djr-deck, sans-serif;
    letter-spacing: 1px;
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    padding-top: 5px;
    color: #4E4E4E;
`;

const ProjectCardWrapper = styled.div`
    display: flex;
    margin-right: 200px;
    flex-direction: column;
    cursor: pointer;

    @media only screen and (max-width: 600px) {
        margin-right: 50px;
    }
`;

const ImageWrapper = styled.img`
    height: 40vh;
    min-width: 300px;
    object-fit: cover;
    max-width: 500px;
    border-radius: 2px;

    @media only screen and (max-width: 600px) {
        height: 200px;
        max-width: 70vw;
        min-width: 50vw;
    }
`;