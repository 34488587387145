import React from "react";
import styled from 'styled-components'
import Projects from "../Projects";
import Header from "../Header";

function Home() {

    return (
        <HomeWrapper>
            <ContentWrapper>
                <Header />
                <Projects />
            </ContentWrapper>
        </HomeWrapper>

    );
}

export default Home;

const HomeWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    max-width: 100%;
    z-index: 1;
    min-height: 100vh;
    position: relative;
    align-items: center;
`;