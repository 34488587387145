import React, {useState} from "react";
import styled from 'styled-components';
import aaron_pic from './assets/aaron_pic.png';
import outline_pic from './assets/outline.png';
import popup_mag from './assets/popup_mag.png';
import up_state from './assets/up_state.png';
import theater_pic from './assets/theater.png';
import { isMobile } from "./helpers";

function Header() {
    const [isTextCopied, setIsTextCopied] = useState('Copy');
    const [showCopyText, setShowCopyText] = useState(false);

    const copyToClipboard = (e) => {
        const el = document.createElement('textarea');
        el.value = 'edwards.aaron12@gmail.com';
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);

        if (isMobile()) {
            setIsTextCopied('Copied :)');
            setShowCopyText(true);
            setTimeout(() => {
                setShowCopyText(false);
                setIsTextCopied('Copy')
            }, 1500);
        } else {
            setIsTextCopied('Copied :)');
        
            if (!showCopyText) {
                setTimeout(() => {
                    setIsTextCopied('Copy')
                }, 2000);
            }
        }
    };

    const showCopyTextFunc = () => {
        if (!isMobile()) {
            setShowCopyText(true);
        }
    }

    const hideCopyTextFunc = () => {
        if (!isMobile()) {
            setShowCopyText(false);
            setIsTextCopied('Copy')
        }
    }
    
    const renderEmailButton = () => {
        return (
            <EmailTextWrapper>
                at <EmailText
                    onClick={copyToClipboard}
                    onMouseEnter={() => showCopyTextFunc()}
                    onMouseLeave={() => hideCopyTextFunc()}
                >
                    {<CopyWrapper>
                        <CopyText style={{
                            visibility: showCopyText ? 'visible' : 'hidden',
                        }}>
                            {isTextCopied}
                        </CopyText>
                    </CopyWrapper>}
                    edwards.aaron12@gmail.com
                </EmailText>
            </EmailTextWrapper>
        )
    }

    return (
        <HeaderWrapper>
            Hey! I'm <LinkWrapper href='https://www.instagram.com/aaronmedwards/' target="_blank">Aaron Edwards</LinkWrapper> <ImageSpan><ImageWrapper src={aaron_pic}/></ImageSpan>. I'm an interdisciplinary writer, story editor and director <ImageSpan><ImageWrapper src={theater_pic}/></ImageSpan> working across text, stage and narrative audio. I've directed shows at BAM, UnionDocs and Lincoln Center <ImageSpan><ImageWrapper src={popup_mag}/></ImageSpan>, written for places like The New York Times and The Atlantic, led multimedia special projects <ImageSpan><ImageWrapper src={outline_pic}/></ImageSpan> and edited podcasts featured in New York Magazine and the Tribeca Festival. I've been named a <LinkWrapper href='https://www.macdowell.org/artists/aaron-edwards' target="_blank">MacDowell Fellow</LinkWrapper> and a Media honoree in <LinkWrapper href='https://www.forbes.com/profile/aaron-edwards/?sh=230a901173a1' target="_blank">Forbes</LinkWrapper>. I'm from Jamaica, Queens (shoutout to P.S. 52), my family is from Jamaica, the island (shoutout to Accompong) and I live in the Hudson Valley (shoutout to trees) <ImageSpan><ImageWrapper src={up_state}/></ImageSpan>. Some of my work's below, and <LinkWrapper href='/morework'>here</LinkWrapper>. Say hi {renderEmailButton()}.
        </HeaderWrapper>

    );
}

export default Header;

/* Header Styles */

const HeaderWrapper = styled.div`
    font-size: 37px;
    letter-spacing: 1px;
    line-height: 45px;
    margin: 0px 50px;
    margin-top: 40px;
    z-index: 5;
    max-width: 1600px;

    @media only screen and (max-width: 600px) {
        margin: 0px 20px;
        margin-top: 30px;
        font-size: 25px;
        line-height: 30px;
    }
`;

/* Header Image Styles */

const ImageSpan = styled.span`
    vertical-align: middle;
    margin: 0 2px;
    line-height: 35px;

    @media only screen and (max-width: 600px) {
        line-height: 22px;
    }
`;

const ImageWrapper = styled.img`
    height: 35px;

    @media only screen and (max-width: 600px) {
        height: 22px;
    }
`;

/* Email Button Styles */

const EmailText = styled.span`
    display: inline-block;
    border-bottom: 1px solid black;
    cursor: pointer;

    :hover {
        color: #E97522;
        border-color: #E97522;
     }

     @media only screen and (max-width: 600px) {
        :hover {
            color: black;
            border-color: black;
        }
    }
`;

const EmailTextWrapper = styled.span`
`;

const CopyWrapper = styled.div`
    position: relative;
    width: 100%;
    margin: 0 5px;

    a {
        text-decoration: none;
    }
`;

const CopyText = styled.div`
    visibility: hidden;
    width: 120px;
    background-color: #E97522;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    font-family: forma-djr-deck, sans-serif;
    letter-spacing: 1.5px;
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    text-transform: uppercase;

    /* Position the tooltip text */
    position: absolute;
    z-index: 1;
    top: -30px;
    left: 50%;
    margin-left: -65px;
    line-height: normal;

    :after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border-width: 5px;
        border-style: solid;
        border-color: #E97522 transparent transparent transparent;
        top: 100%;
        left: 50%;
        margin-left: -5px;
    }
`;


/* Link Styles */

const LinkWrapper = styled.a`
    text-decoration: none;
    color: inherit;
    border-bottom: 1px solid black;

    :hover {
       color: #E97522;
       border-color: #E97522;
    }
`;
